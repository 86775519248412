import { HeaderContainer, Link, LogoLink, MenuButton, Nav } from "./Header.styled";
import { useState } from "react";
import { Sidebar } from "./Sidebar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect } from "react";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [isOpen]);
  return (
    <HeaderContainer>
      <LogoLink href="/">
        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/logo-header.svg`} alt="Constacts" />
      </LogoLink>
      <Sidebar isOpen={isOpen} closeSidebar={() => setIsOpen(false)} />
      <Nav>
        <Link href="https://ragtacts.com/" target="_blank" rel="noopener noreferrer">
          Ragtacts
        </Link>
        <Link href="https://teams.constacts.com/" target="_blank" rel="noopener noreferrer">
          Teams
        </Link>
        <Link href="https://what-is-it.constacts.com/" target="_blank" rel="noopener noreferrer">
          What is it?
        </Link>
        <Link href="mailto:peace@constacts.com" target="_blank" rel="noopener noreferrer">
          Contact us
        </Link>
      </Nav>
      <MenuButton onClick={() => setIsOpen(true)}>
        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/btn-menu.svg`} alt="menu" />
      </MenuButton>
    </HeaderContainer>
  );
};
