import styled from "styled-components";
import { Container } from "../styles/common/common.styled";

export const AnbuContainer = styled(Container)`
  position: relative;
  height: 100vh;
  background-color: #fff;
`;

export const Paragraph = styled.p`
  position: absolute;
  z-index: 1;
  top: 6.2%;
  left: 5.1%;
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  font-family: 'Poppins', 'NanumSquareEB';

  @media (min-width: 768px) {
    top: 7.8%;
    left: 4%;
    font-size: 37px;
    line-height: 52px;
  }
  @media (min-width: 1080px) {
    top: 10.1%;
    left: 55%;
    font-size: 39px;
    line-height: 55px;
  }
`;

export const Highlight = styled.strong`
  background-color: #97ffab;
`;

export const DevieImage = styled.img`
  position: absolute;
  top: 28.8%;
  right: 0;
  height: 65%;
  object-fit: scale-down;

  @media (min-width: 768px) {
    top: 32.6%;
    right: 3.9%;
    width: 77.3%;
    height: 61.9%;
  }

  @media (min-width: 1080px) {
    top: 9%;
    right: auto;
    left: 9%;
    width: 53.1%;
    height: 86%;
  }
`;
