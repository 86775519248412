import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareR';
    src: url(/fonts/NanumSquareR.otf) format('opentype');	
    unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
  }

  @font-face {
    font-family: 'NanumSquareB';
    src: url(/fonts/NanumSquareB.otf) format('opentype');	
    unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
  }

  @font-face {
    font-family: 'NanumSquareEB';
    src: url(/fonts/NanumSquareEB.otf) format('opentype');	
    unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', 'NanumSquareR', sans-serif;
  }

  body{
    margin: 0;
  }

  a{
    text-decoration: none;
  }

  img{
    vertical-align: top;
  }

  p{
    margin: 0;
  }

  button{
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  .onlyMobile{
    @media (min-width: 768px) {
      display: none;
    }
  }
  .onlyT{
    @media (max-width: 767px) {
      display: none;
    }
    
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;
