import styled from "styled-components";
import { Container } from "../styles/common/common.styled";

export const HeroContainer = styled(Container)`
  position: relative;
  height: 100vh;
  background-color: #2a35ea;
`;

export const HeroImageWrap = styled.picture`
  position: absolute;
  top: 13.8%;
  left: 4.8%;
  width: 77.9%;
  height: 61.6%;
  object-fit: scale-down;
  @media (min-width: 768px) {
    inset: 0;
    width: 92.1%;
    height: 34%;
    margin: auto;
  }
  @media (min-width: 1080px) {
    width: 80%;
    height: 60%;
  }
`;
export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  pointer-events: none;
`;

export const ScrollDownButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 90px;
  left: 0;
  width: 28px;
  margin: 0 auto;
  @media (min-width: 1080px) {
    bottom: 46px;
  }
`;
