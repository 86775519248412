import { Inner, Paragraph, TextContainer } from "./Text.styled";

export const Text = () => {
  return (
    <TextContainer>
      <Inner>
        <Paragraph>
          친구들의 소식을 볼 수 있는 소셜 미디어, 서로 부담없이 근황을 나누세요!
        </Paragraph>
        <Paragraph>
          나는 친구들에게 어떤 사람일까? 
          <br/>
          친구들의 응답으로 나의 네트워크 지도가 완성됩니다</Paragraph>
        <Paragraph>
          Stay connected with your real friends <br className="onlyT" />
          and supporters.
        </Paragraph>
        <Paragraph>In Constacts, unnecessary and uncomfortable followers are automatically cut off.</Paragraph>
        <Paragraph>
          No need to feel social pressure, <br className="onlyT" />
          algorithms will help you.
        </Paragraph>
      </Inner>
    </TextContainer>
  );
};
