import { HeroContainer, HeroImage, HeroImageWrap, ScrollDownButton } from "./Hero.styled";
import { Header } from "./Header";
import * as Scroll from "react-scroll";

export const Hero = () => {
  const handleClick = () => {
    Scroll.scroller.scrollTo("registration", {
      duration: 500,
      delay: 100,
      smooth: true,
    });
  };

  return (
    <HeroContainer>
      <Header />
      <HeroImageWrap>
        <source srcSet={`${process.env.REACT_APP_ASSETS_URL}/assets/img-maingraphic-pc@2x.png`} media="(min-width: 1080px)" />
        <source srcSet={`${process.env.REACT_APP_ASSETS_URL}/assets/img-maingraphic-t@2x.png`} media="(min-width: 768px)" />
        <HeroImage src={`${process.env.REACT_APP_ASSETS_URL}/assets/img-maingraphic-m@2x.png`} alt="We're Constacts. Let's have a Lo-fi communication with us." />
      </HeroImageWrap>
      <ScrollDownButton onClick={handleClick}>
        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/btn-downscroll.svg`} alt="scroll down" />
      </ScrollDownButton>
    </HeroContainer>
  );
};
