import {  ButtonWrraper, Inner, RegisterationTopContainer, AppDownloadLink, QrWrapper } from "./RegisterationTop.styled";

import { Element } from "react-scroll";

export const RegisterationTop = () => {
  return (
    <RegisterationTopContainer>
      <Element name="registration" />
      <Inner>
        <ButtonWrraper>
          <AppDownloadLink href="https://apps.apple.com/app/1641060297" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/apple-download.png`} width='100%' alt="Apple Download Icon" />
          </AppDownloadLink>
          <AppDownloadLink href="https://play.google.com/store/apps/details?id=com.constacts.constacts" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/google-download.png`} width='100%' alt="Google Download Icon" />
          </AppDownloadLink>
        </ButtonWrraper>
        <QrWrapper>
          <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/qr-code.png`} width='100%' alt="QR Code" />
        </QrWrapper>
      </Inner>
    </RegisterationTopContainer>
  );
};
