import { Copyright, FooterContainer, Inner, Link, LinkWrap, LogoLink, RightSideLinkWrap } from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterContainer>
      <Inner>
        <LinkWrap>
          <LogoLink href="/">
            <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/logo-footer.svg`} alt="Constacts" />
          </LogoLink>

          <RightSideLinkWrap>
            <Link href=" https://teams.constacts.com/" target="_blank" rel="noopener noreferrer">
              Teams
            </Link>
            <Link href="mailto:peace@constacts.com" target="_blank" rel="noopener noreferrer">
              Contact us
            </Link>
          </RightSideLinkWrap>
        </LinkWrap>
        <Copyright>Copyright © Constacts, Inc. All Rights Reserved.</Copyright>
      </Inner>
    </FooterContainer>
  );
};
