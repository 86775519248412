import styled from "styled-components";
import { Container } from "../styles/common/common.styled";

export const TextContainer = styled(Container)`
  background-color: #f8f9fa;
`;

export const Inner = styled.div`
  padding: 72px 0;

  @media (min-width: 768px) {
    padding: 88px 0;
  }

  @media (min-width: 1080px) {
    padding: 150px 0;
  }
`;

export const Paragraph = styled.p`
  width: 339px;
  margin: 32px auto 0;
  font-size: 19px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.48px;
  text-align: center;
  font-family: 'Poppins', 'NanumSquareEB';

  &:nth-child(1) {
    margin-top: 0;
    color: #252537;
  }

  &:nth-child(2) {
    color: #515763;
  }

  &:nth-child(3) {
    color: #717581;
  }

  @media (min-width: 768px) {
    width: 708px;
    margin-top: 46px;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.56px;
  }

  @media (min-width: 1080px) {
    width: 1024px;
    margin-top: 60px;
    font-size: 34px;
    line-height: 45px;
    letter-spacing: -0.5px;
  }
`;
