import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  @media (min-width: 768px) {
    padding-top: 28px;
  }    
  @media (min-width: 768px) {
    inset: 0;
    width: 98%;
    height: 34%;
    margin: auto;
  }
  @media (min-width: 1080px) {
    inset: 0;
    max-width: 82%;
    height: 60%;
  }
`;

export const LogoLink = styled.a`
  display: flex;
  height: 25px;
  align-items: center;
`;

export const Link = styled.a`
  margin-left: 50px;
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  vertical-align: top;
`;

export const Nav = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const MenuButton = styled.button`
  @media (min-width: 768px) {
    display: none;
  }
`;
