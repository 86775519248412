import styled from "styled-components";
import { Container } from "../styles/common/common.styled";

export const RegisterationTopContainer = styled(Container)`
  position: relative;
  overflow: hidden;
  background-color: #040732;
  background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/img-banner-bg@2x.png`});
  background-position: center;
  background-size: auto 139px;
  background-repeat: repeat-x;
  font-family: sans-serif;
  @media (min-width: 768px) {
    background-size: auto 277px;
  }
`;

export const Inner = styled.div`
  padding: 25px 0 45px;
  @media (min-width: 768px) {
    padding: 45px 0 87px;
    background-size: auto 277px;
  }
  @media (min-width: 768px) {
    width: 98%;
    margin: auto;
  }
  @media (min-width: 1080px) {
    padding: 70px 0;
    width: 82%;
    margin: auto;
  }
`;

export const QrWrapper = styled.div`
  width: 260px;
  margin: 0 auto;
  margin-top: 45px;
`;

export const Link = styled.a`
  display: block;
  width: 110px;
  height: 39px;
  margin-top: 17px;
  background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/btn-banner-m.svg`});
  background-size: cover;
  font-size: 0;

  @media (min-width: 768px) {
    width: 133px;
    height: 50px;
    margin-top: 18px;
    background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/btn-banner-t.svg`});
  }

  @media (min-width: 1080px) {
    width: 160px;
    height: 60px;
    margin-top: 26px;
    background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/btn-banner-pc.svg`});
  }
`;

export const GreenBanner = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 375px;
  height: 69px;
  background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/img-greenbanner-m@2x.png`});
  background-size: cover;

  @media (min-width: 768px) {
    width: 737px;
    height: 133px;
    background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/img-greenbanner-t@2x.png`});
  }

  @media (min-width: 1080px) {
    width: 960px;
    height: 170px;
    background-image: url(${`${process.env.REACT_APP_ASSETS_URL}/assets/img-greenbanner-pc@2x.png`});
  }
`;


export const ButtonWrraper = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
`;

export const AppDownloadLink = styled.a`
  width: 280px;
`
