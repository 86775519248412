import styled from "styled-components";

export const Container = styled.section`
  padding: 0 18px;
  @media (min-width: 768px) {
    padding: 0 30px;
  }

  @media (min-width: 1080px) {
    padding: 0 28px;
  }
`;
