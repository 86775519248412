import { CloseButton, Dimmed, Link, Wrap } from "./Sidebar.styled";

interface SidebarProps {
  isOpen: boolean;
  closeSidebar: () => void;
}

export const Sidebar = (props: SidebarProps) => {
  return (
    <>
      <Wrap isOpen={props.isOpen}>
        <CloseButton onClick={props.closeSidebar}>
          <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/btn-menu-close.svg`} alt="close" />
        </CloseButton>
        <Link href="https://ragtacts.com/" target="_blank" rel="noopener noreferrer">
          Ragtacts
        </Link>
        <Link href="https://what-is-it.constacts.com/" target="_blank" rel="noopener noreferrer">
          What is it?
        </Link>
        <Link href="mailto:peace@constacts.com" target="_blank" rel="noopener noreferrer">
          Contact us
        </Link>
        <Link href="https://teams.constacts.com/" target="_blank" rel="noopener noreferrer">
          Teams
        </Link>
      </Wrap>
      <Dimmed isOpen={props.isOpen} onClick={props.closeSidebar} />
    </>
  );
};
